import { reducerToGTM } from "../../gtm";
import actions from "./actions";
import initialState from "./initialState";

const manageSelectedPets = (arr, id, remove = false) => {
  const currIdx = arr.indexOf(id);

  if (!remove) {
    // already inside and trying to add
    if (currIdx >= 0) return arr;
    arr.push(id);
  } else {
    // trying to remove and it doesnt exist
    if (currIdx < 0) return arr;
    arr.splice(currIdx, 1);
  }

  return arr;
};

/**
 *
 * @param { initialState } state
 * @param {{ type: actions, payload: Object }} contextPayload
 * @returns
 */
export default function Reducer(state, { type, payload }) {
  //console.log(type, payload);
  const petIdx = payload.petIdx;
  const pets = []; // [...state.patients];

  //console.table({ petIndex: petIdx, pets });

  // "Middleware"-like to push data to GTM
  reducerToGTM(type, payload);

  switch (type) {
    case actions.SET_TYPE_ID:
      const { appointmentType, appointmentTypeId } = payload;
      return {
        ...state,
        appointmentType,
        appointmentTypeId,
        selectedPatients: [],
      };
    case actions.SET_API_DATA:
      const { careTypes, appointmentTypes } = payload;
      return { ...state, careTypes, appointmentTypes };
    case actions.SET_LOCATION:
      const { searchAddress } = payload;
      return { ...state, searchAddress };
    case actions.SET_APPOINTMENT:
      const { appointment, location } = payload;
      return {
        ...state,
        selectedAppointmentSlot: appointment,
        selectedLocation: location,
      };
    case actions.DELETE_PET: {
      const deletedPets = { ...state.patients };
      delete deletedPets[payload.id];

      // Also need to clear selected pets if available.
      const selectedPatients = [...state.selectedPatients];
      const updatedSelectedPatienta = selectedPatients.filter(
        (sp) => sp !== payload.id
      );

      return {
        ...state,
        patients: { ...deletedPets },
        selectedPatients: updatedSelectedPatienta,
      };
    }
    case actions.MANAGE_PET:
      const managedPet = {
        [payload.pet.id]: {
          ...payload.pet,
        },
      };
      return { ...state, patients: { ...state.patients, ...managedPet } };
    case actions.SELECT_PET:
      return {
        ...state,
        selectedPatients: manageSelectedPets(
          [...state.selectedPatients],
          payload.id,
          false
        ),
      };
    case actions.DESELECT_PET:
      return {
        ...state,
        selectedPatients: manageSelectedPets(
          [...state.selectedPatients],
          payload.id,
          true
        ),
      };
    case actions.SET_EMAIL:
      return {
        ...state,
        email: payload.email,
      };
    case actions.SET_FAMILY_NAME:
      return {
        ...state,
        familyName: payload.familyName,
      };
    case actions.SET_GIVEN_NAME:
      return {
        ...state,
        givenName: payload.givenName,
      };
    case actions.SET_APPOINTMENT_RANGE:
      return {
        ...state,
        appointmentRange: {
          ...payload.appointmentRange,
        },
      };
    case actions.SET_GLOBAL_STATE: {
      return {
        ...state,
        ...payload,
      };
    }
    case actions.SET_CARE_REASON: {
      //console.log("the care reason", payload.careReason);
      return {
        ...state,
        appointmentTypeId: payload.careReason.selectedItem.value,
      };
    }
    // case actions.SET_PREFERRED_PROVIDERS:
    //   return {
    //     ...state,
    //     preferredProviders: payload.preferredProviders,
    //   };

    case actions.SET_ADDRESS:
      return {
        ...state,
        address: payload.address,
      };
    case actions.SET_CREDITCARD_INFO:
      return {
        ...state,
        cardconnectToken: payload.cardconnectToken,
        cardconnectExpiry: payload.cardconnectExpiry,
      };
    case actions.SET_MAIN_SERVICE:
      return {
        ...state,
        serviceOption: payload.service,
      };
    case actions.SET_ADD_ONS:
      const addOnIdx = state.addOns.indexOf(payload.addOn);
      //console.log(addOnIdx);
      // const addOnIdx = state.addOns.findIndex(
      // 	(addOn) => addOn.id === payload.addOn.id
      // );
      const updatedAddons = [...state.addOns];
      if (addOnIdx >= 0) {
        updatedAddons.splice(addOnIdx, 1);
      } else {
        updatedAddons.push(payload.addOn);
      }
      return {
        ...state,
        addOns: updatedAddons,
      };
    case actions.SET_TERMS:
      return { ...state, terms: payload.terms };

    case actions.SET_CLIENT_AUTH_DATA:
      return {
        ...state,
        ...payload.client,
        userIsAuthed: payload.userIsAuthed,
        patients: { ...payload.patients },
      };
    case actions.SET_MESSAGING:
      return {
        ...state,
        receiveMessaging: payload.receiveMessaging,
      };

    case actions.SET_PHONE_NUMBER:
      return {
        ...state,
        phone: payload.phone,
      };

    case actions.SET_GRAPEVINE:
      return {
        ...state,
        grapevine: payload.grapevine,
      };

    case actions.SET_USER_AUTH:
      return {
        ...state,
        userIsAuthed: payload.userIsAuthed,
      };
      case actions.RESET:
        return {
          ...state,
  
          // appointment
          appointmentType: null,
          appointmentTypeId: null,
          selectedAppointmentSlot: {},
          location: null,
          appointmentSortType: null,
          styleOption: null,
          addOns: [],
          careReason: null,
          // patients
          selectedPatients: [],
          // client info
          terms: false,
        };
    case actions.SET_APPOINTMENT_REASON:
      return {
        ...state,
        appointmentReason: payload.appointmentReason,
      };
    case actions.SET_APPOINTMENT_REASON_OTHER:
      return {
        ...state,
        appointmentReasonOtherText: payload.appointmentReasonOtherText,
      };
    case actions.SET_NEW_USER:
      return {
        ...state,
        newUser: payload.newUser,
      };

    case actions.SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: payload.authToken,
      };
    case actions.SET_LOGIN_WITH:
      return {
        ...state,
        loginWith: payload.loginWith,
      };
    case actions.SET_CENTER_LOCATION:
      return {
        ...state,
        centerLocation: payload.centerLocation,
      };
      case actions.SET_IS_TODAYS_APPOINTMENT:
      return { ...state, isTodaysAppointment: payload.isTodaysAppointment };
	  

    default:
      throw new Error(`${type} is not handled in this reducer.`);
  }
}
