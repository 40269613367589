import React, { useState, Fragment, useEffect } from "react";
import style from "../ReferAFriend/ReferAFriend.module.css";
import HeartandpawLogo from "../../assets/images/Heartandpaw-logo.svg";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { utils } from "react-modern-calendar-datepicker";
import { Dialog, Transition } from "@headlessui/react";
import CloseIcon from "../../assets/images/Close.svg";
import LearnMore from "../../assets/images/Learn-more.svg";
import ReferEmoji from "../../assets/images/refer-emoji.svg";
import ReferHeartPaw from "../../assets/images/heart-paw-refer.svg";
import ReferHeartPawMobile from "../../assets/images/heart-paw-refer-mobile.svg";
import { API_DOMAIN, portalURL } from "../../const";
import DatePicker from "react-modern-calendar-datepicker";
import CalendarInput from "../../components/controls/Calendar/Input";
import { paramsOnLoad } from "../../utils";
import axios from "axios";
import Loader from "../../components/generics/Loader";
import ErrorAlert from "../../components/errors/ErrorAlert.jsx";
import SuccessIcon from "../../assets/images/success.svg";

export default function ReferAFriend() {
  const [selectedDay, setSelectedDay] = useState(null);
  const [isCalendar, setIsCalendar] = useState(false);
  const [isOpen, setIsOpen] = useState(null);
  const [isName, setIsName] = useState("");
  const [isSending, setSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isMessage, setIsMessage] = useState("");
  const [isErrorMessage, setIsErrorMessage] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  window.onresize = () => {
    setWindowWidth(window.innerWidth);
  };


  const refereeId = paramsOnLoad.get("refereeId");
  const uvi = paramsOnLoad.get("uvi");

  const formatDate = ({ day, month, year }) => {
    const _month = month < 10 ? `0${month}` : month;
    const _day = day < 10 ? `0${day}` : day;
    return `${year}-${_month}-${_day}`;
  };

  const onClickHandler = () => {
    setIsSuccess(false);
    setSending(true);
    axios
      .post(`${portalURL}/api/referfriend/remainder`, {
        date: formatDate(selectedDay),
        refereeId: refereeId,
        uvi: uvi,
      })
      .then((resp) => {
        if (resp.status === 200) {
          setIsSuccess(true);
          setSending(false);
          setIsCalendar(false);
        }
      })
      .catch((err) => {
        setIsSuccess(false);
        console.log("Errr==>", err);
        setIsOpen(true);
        setIsMessage(err?.response?.data?.message);
        setSending(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`${portalURL}/api/referfriend/referee-info`, {
        refereeId: refereeId,
      })
      .then((resp) => {
        setIsLoading(false);
        if (resp.status === 200) {
          setIsName(resp?.data?.refeerDetails?.name);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Errr==>", err);
        setIsErrorMessage(err?.response?.data?.message);
      });
  }, []);

  console.log("utils().getToday()", utils().getToday());

  const currDate = new Date();
  // const tomorrow = new Date(today);
  // tomorrow.setDate(tomorrow.getDate() + 1);

  let currDay = currDate.getDate() + 1;
  let currMonth = currDate.getMonth() + 1;
  let currYear = currDate.getFullYear();

  let dateObj = {
    year: currYear,
    month: currMonth,
    day: currDay,
  };


  if (isLoading)
    return (
      <div className={style["success-msg"]}>
        {" "}
        <Loader />{" "}
      </div>
    );

  if (isSuccess) {
    return (
      <div className={style["success-msg"]}>
        <div className="mx-auto max-w-3xl Successful_booking">
          <div className="flex flex-col gap-4">
            <div className="col-span-2">
              <img
                src={SuccessIcon}
                className="text-primary-500 h-12 w-12 mb-4 Successful_img"
              />

              <h3 className="text-primary-500 font-bold  md:text-center md:text-2xl md:mx-auto Successful_heading_1">
                Your reminder has been set.
              </h3>
            </div>
            <div className="Book_another_visit">
              <a
                href={"/"}
                className="Book_another_visit_btn px-4 py-2 text-sm font-medium
                text-white bg-primary-500 border border-transparent rounded-md
                hover:bg-primary-200 hover:text-black focus:outline-none
                focus-visible:ring-2"
              >
                {" "}
                Go to Home
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onCloseErrorHandler = () => {
    window.location.href = "/";
  };

  if (isErrorMessage) {
    return (
      <ErrorAlert
        isOpen={isErrorMessage ? true : false}
        apiErrorCode={isErrorMessage ? isErrorMessage : "Something went wrong!"}
        onCloseHandler={onCloseErrorHandler}
      />
    );
  }

  return (
    <div className={style.page}>
      <div className={style["page-part"]}>
        <div className={style.header}>
          <div className={style.logo}>
            <img src={HeartandpawLogo} className={style.ran} />
          </div>
          <div className={style["header-right"]}>
            <div class={style["ribbon-right"]}>
              <img src={ReferEmoji} />
              &nbsp;You have been invited by {isName} !!
            </div>
          </div>
        </div>
        <div className={style["page-body"]}>
          <div className={style["title-parent"]}>
            <div className={style["book-appt"]}>
              <p style={{ fontWeight: "bold" }}>
                {" "}
                Book and complete your first veterinary appointment and earn
                &nbsp;
                <b>$50!</b>
              </p>
            </div>
          </div>
          <div className={style["invite-text"]}>
            <img src={ReferEmoji} className={style["emoji-image"]}/>
            <div style={{ paddingLeft: "5px" }}>
              {windowWidth<768 ? `You've been referred by ${isName}!` :`You have been invited by ${isName}!`}
            </div>
          </div>

          <div className={style["page-part-2"]}>
            <div className={style["image-parent"]}>
              <img className={style["refer-image"]} src={windowWidth<768 ? ReferHeartPawMobile :ReferHeartPaw} />
            </div>

            <div className={style.text}>
             {windowWidth < 768 ? '$50 CREDIT' : 'At Heart + Paw, pets are family. We offer pet-focused, low-stress veterinary care experiences for pets and their parents. Join our family and book an appointment to meet the team.'}
            </div>
            <div className={style["text-part"]}>
              Use the custom link below to book your first appointment with us and you'll get $50 off!*
            </div>
            <div className={style["button-parent"]}>
              <button className={style["book-button"]}>
                <div className={style["button-text"]}>
                  <a href={`/?type=care-style-play&refereeId=${refereeId}`} style={{ color: "#07493D"}}>
                    Book appointment{" "}
                  </a>
                </div>
              </button>
            </div>
            <div className={style["remind-parent"]}>
              {uvi && refereeId && (
                <button
                  className={style["text-remind"]}
                  onClick={() => setIsCalendar(true)}
                >
                  Remind me later
                </button>
              )}

              <Transition appear show={isCalendar} as={Fragment}>
                <Dialog
                  as="div"
                  className="fixed inset-0 z-10 overflow-y-auto"
                  onClose={() => {
                    setIsCalendar(false);
                    setIsMessage("");
                  }}
                >
                  <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Dialog.Overlay
                        style={{ opacity: 0.3 }}
                        className="fixed inset-0 bg-black pointer-events-none"
                      />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                      className="inline-block h-screen align-middle"
                      aria-hidden="true"
                    >
                      &#8203;
                    </span>
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <div className="VerifyOtp inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                        <Dialog.Title
                          as="h3"
                          className="model_title flex justify-between items-center text-lg font-medium leading-6 text-gray-900 mb-4"
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setIsCalendar(false);
                              setIsMessage("");
                            }}
                          >
                            <img src={CloseIcon} />
                          </span>
                        </Dialog.Title>
                        <div
                          className="verify_otp_contant"
                          style={{ paddingTop: "30px" }}
                        >
                          <DatePicker
                            value={selectedDay}
                            onChange={setSelectedDay}
                            inputPlaceholder="Select a day"
                            shouldHighlightWeekends
                            minimumDate={dateObj}
                            calendarPopperPosition="bottom"
                          />
                          {isOpen && (
                            <div style={{ color: "red", paddingTop: "13px" }}>
                              {isMessage}
                            </div>
                          )}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: "30px",
                            }}
                          >
                            <button
                              className="px-4 py-2 text-sm font-medium text-white bg-primary-500 border border-transparent rounded-md hover:bg-primary-200 hover:text-black focus:outline-none focus-visible:ring-2"
                              disabled={selectedDay == null}
                              onClick={onClickHandler}
                            >
                              {isSending ? "Submitting" : "Submit"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Transition.Child>
                  </div>
                </Dialog>
              </Transition>
            </div>
          </div>
          <div className={style["book-text1"]}>
            Offering full-service veterinary care including routine and
            preventative wellness, dental care, radiology, surgery and more, so
            our pets can live their happiest lives.
          </div>
          <div className={style["book-text2"]}>
            <span className={style["para1"]}>*Referral credits can be used for Care, Style, Play and Stay services available at Heart + Paw locations. 
              Blue Rabbit pharmacy products, pet insurance and retail items are excluded from referral credit use. </span> <br/> <br/>
            <span className={style["para2"]}> Visit <a
                className={style.link}
                href="https://heartandpaw.com"
                target="_blank"
                style={{ textDecoration: "none"}}
              >
                heartandpaw.com/locations
              </a> to see services offered at each location.</span>
          </div>
          <div className={style["mobile-block"]}>
            <p style={{ fontWeight: "bold", padding: "7px 5px" }}>
              Learn more about our services at{" "}
              <a
                className={style.link}
                href="https://heartandpaw.com"
                target="_blank"
              >
                heartandpaw.com
              </a>
            </p>
          </div>
          <div className={style.blocks}>
            <div
              className={style["block-grand"]}
              style={{ paddingLeft: "0px" }}
            >
              <div className={style["block-parent"]}>
                <div className={style["block-title"]}>Routine Wellness</div>
                <div className={style["block-desc"]}>
                  Stay up-to-date with your pet’s vaccinations and get regular
                  health screenings with routine low-stress wellness visits.
                </div>
                <div className={style["learn-more-parent"]}>
                  <div className={style["learn-more"]}>
                    <a
                      href="https://heartandpaw.com/healthcare-services/routine-wellness"
                      target="_blank"
                    >
                      Learn More{" "}
                    </a>
                  </div>
                  <div className={style["learn-more-icon"]}>
                    <img src={LearnMore} className={style.learn} />
                  </div>
                </div>
              </div>
            </div>
            <div className={style["block-grand"]}>
              <div className={style["block-parent"]}>
                <div className={style["block-title"]}>Dental</div>
                <div className={style["block-desc"]}>
                  Dental care that’s finely tailored to your pet’s specific
                  needs.
                </div>
                <div className={style["learn-more-parent"]}>
                  <div className={style["learn-more"]}>
                    <a
                      href="https://heartandpaw.com/healthcare-services/dental"
                      target="_blank"
                    >
                      Learn More{" "}
                    </a>
                  </div>
                  <div className={style["learn-more-icon"]}>
                    <img src={LearnMore} className={style.learn} />
                  </div>
                </div>
              </div>
            </div>
            <div className={style["block-grand"]}>
              <div className={style["block-parent"]}>
                <div className={style["block-title"]}>Surgery</div>
                <div className={style["block-desc"]}>
                  Our state-of-the art surgical suites have all of the
                  monitoring equipment used in human medicine.
                </div>
                <div className={style["learn-more-parent"]}>
                  <div className={style["learn-more"]}>
                    <a
                      href="https://heartandpaw.com/healthcare-services/surgery"
                      target="_blank"
                    >
                      Learn More{" "}
                    </a>
                  </div>
                  <div className={style["learn-more-icon"]}>
                    <img src={LearnMore} className={style.learn} />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={style["block-grand"]}
              style={{ paddingRight: "0px" }}
            >
              <div className={style["block-parent"]}>
                <div className={style["block-title"]}>Radiology</div>
                <div className={style["block-desc"]}>
                  Our cutting-edge radiology and ultrasound equipment is
                  designed to deliver results quickly and in a low-stress
                  environment.
                </div>
                <div className={style["learn-more-parent"]}>
                  <div className={style["learn-more"]}>
                    <a
                      href="https://heartandpaw.com/healthcare-services/radiology"
                      target="_blank"
                      style={{ display: "flex" }}
                    >
                      Learn More{" "}
                      <div className={style["learn-more-icon"]}>
                        <img src={LearnMore} className={style.learn} />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style["foot-sentence-1"]}>
            Learn more about our veterinary care services at
            <a
              className={style.link}
              href="https://heartandpaw.com/"
              target="_blank"
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              {" "}
              heartandpaw.com
            </a>
            .
          </div>
          {windowWidth<769 ? <div className={style["foot-sentence-2"]}>
          Learn more about Heart + Paw by visiting
            <a
              className={style.link}
              href="https://heartandpaw.com/"
              target="_blank"
              style={{ fontWeight: "500", textDecorationLine: "none" }}
            >
              {" "}
              heartandpaw.com
            </a>
            .
          </div> : <div className={style["foot-sentence-2"]}>
            Looking for grooming, dog daycare or boarding? Visit&nbsp;
            <a
              className={style.link}
              href="https://heartandpaw.com/locations"
              target="_blank"
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              heartandpaw.com/locations
            </a>
            &nbsp;to see the services offered at each location.
          </div>}
        </div>
      </div>
    </div>
  );
}
