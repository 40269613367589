const SITE_URL = window.location.hostname;
const AZURE_DOMAIN = (function (hostname) {
  //console.log(hostname);
  switch (hostname) {
    case "localhost":
      return "https://preprodapis.heartandpaw.com";
    case "bookingdev.heartandpaw.com":
      return "https://devapis.heartandpaw.com";

    case "bookingstage.heartandpaw.com":
      return "https://uatapis.heartandpaw.com";

    case "bookingpreprod.heartandpaw.com":
      return "https://preprodapis.heartandpaw.com";

    default:
      return "https://cloud.heartandpaw.com";
  }
})(SITE_URL);

/**
 * These are dynamically generated to prevent an issue where
 * the API tries to auth on booking-test-api.hap.com but actual calls
 * are going to boooking-api.hap.com.
 */
const API_DOMAIN = `${AZURE_DOMAIN}/api`;
const PROVIDER_LOGIN_URL = `${AZURE_DOMAIN}/.auth/login/`;
const PROVIDER_LOGOUT_URL = `${AZURE_DOMAIN}/.auth/logout`;
const LOCATIONS_API_URL = `https://heartandpaw.com/wp-json/hap/locations`;

const SESSION_STORAGE_KEYS = {
  LOCATIONS: "HAP_BOOKING_LOCATIONS_V1",
  BREEDS: "HAP_BREEDS_V1",
  EXOTIC_SPECIES: "HAP_EXOTIC_SPECIES_V1",
  GRAPEVINE: "HAP_GRAPEVINE_V1",
  CARE_REASONS: "HAP_CARE_REASONS_V1",
  APPOINTMENT_TYPES: "HAP_APPOINTMENT_TYPES_V1",
};
//** Location API */
export const MAP_API_KEY = "AIzaSyD5EB9A3bqXy3e7SKH9HyCKXN8uA8b0ao4";

// Google Auth key
export const REACT_APP_GOOGLE_CLIENT_ID =
  "497068155206-ngkbn0l2kie3fnrkfn8p2fblku0mjnal.apps.googleusercontent.com";

// This is auth key for authToken Encrypt/Decrypt
export const SECSECRET_KEY_AUTH_TOKEN =
  "eJftOmeA1wKLmLw8YQLhNZ9uTTULiSktwew2323rerer";

export const getDistanceFromLatLon = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d * 0.621371; // convert to miles;
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};
/*****/

export function returnEnvDomain() {
  if (AZURE_DOMAIN === "https://cloud.heartandpaw.com") {
    return "https://apis.heartandpaw.com";
  } else if (AZURE_DOMAIN === "https://uatapis.heartandpaw.com") {
    return "https://myapisuat.heartandpaw.com";
  } else if (AZURE_DOMAIN === "https://preprodapis.heartandpaw.com") {
    return "https://myapispreprod.heartandpaw.com";
  } else {
    return "https://myapisdev.heartandpaw.com";
  }
}

function isProductionEnv() {
  if (AZURE_DOMAIN === "https://cloud.heartandpaw.com") return true;
  else return false;
}
// For Idle user message alert
export const LOGIN_PAGE_MESSAGE = (location) => {
  let number = location?.meta?.locations_meta_phone;
  let tel = number?.split("-").join("");
  let isLocation = Object.keys(location)?.length !== 0 ? true : false;
  return (
    <>
      <h3>Have questions?</h3>
      {isLocation ? (
        <p style={{ lineHeight: "1.6" }}>
          {" "}
          We can help! Complete your booking by logging into our secure portal
          or call us at{" "}
          <b>
            <a href={"tel:" + tel}>{number}</a>
          </b>{" "}
          for immediate assistance.
        </p>
      ) : (
        <p style={{ lineHeight: "1.6" }}>
          {" "}
          We can help! Complete your booking by logging into our secure portal.
        </p>
      )}
    </>
  );
};
export const NEW_CARE_CLIENT_MESSAGE = (location) => {
  let number = location?.meta?.locations_meta_phone;
  let tel = number?.split("-").join("");
  let isLocation = Object.keys(location)?.length !== 0 ? true : false;
  return (
    <>
      <h3>Have questions?</h3>
      {isLocation ? (
        <p style={{ lineHeight: "1.6" }}>
          We can help! <span className="clickhere"> Click here</span> to
          complete your appointment or for immediate assistance, call us at{" "}
          <b>
            <a href={"tel:" + tel}>{number}</a>
          </b>{" "}
          and we will do our best to fit you in.
        </p>
      ) : (
        <p style={{ lineHeight: "1.6" }}>
          We can help! <span className="clickhere"> Click here</span> to
          complete your appointment.
          {/* or for immediate assistance, we will do our
          best to fit you in. */}
        </p>
      )}
    </>
  );
};
export const RETURNING_CARE_CLIENT_MESSAGE = (location) => {
  let number = location?.meta?.locations_meta_phone;
  let tel = number?.split("-").join("");
  let isLocation = Object.keys(location)?.length !== 0 ? true : false;
  return (
    <>
      <h3>Are you sure you do not want to finish booking an appointment? </h3>
      {isLocation ? (
        <p style={{ lineHeight: "1.6" }}>
          <span className="clickhere"> Click here</span> to complete your
          appointment or for immediate assistance, call us at{" "}
          <b>
            <a href={"tel:" + tel}>{number}</a>
          </b>{" "}
          and we will do our best to fit you in.
        </p>
      ) : (
        <p style={{ lineHeight: "1.6" }}>
          <span className="clickhere"> Click here</span> to complete your
          appointment.
          {/* or for immediate assistance, we will do our
          best to fit you in. */}
        </p>
      )}
    </>
  );
};

// Auth cookie expire 15 days
export const AUTH_COOKIE_EXP = 15;
export const portalURL = "https://apis.heartandpaw.com";

export const walkinAllowedLocations = [
  {
    id: "22863",
    name: "Apache",
    coordinates: { lat: "36.065777", long: "-86.687996" },
  },
  {
    id: "22715",
    name: "Centerreach",
    coordinates: { lat: "40.858452", long: "-73.097107" },
  },
  {
    id: "16996",
    name: "Ballston",
    coordinates: { lat: "38.879360", long: "-77.109810" },
  },
  {
    id: "268",
    name: "Callowhill",
    coordinates: { lat: "39.959137", long: "-75.163132" },
  },
  {
    id: "152",
    name: "GlenMills",
    coordinates: { lat: "39.863590", long: "-75.542282" },
  },
  {
    id: "22903",
    name: "LakeHopatcong",
    coordinates: { lat: "40.956791", long: "-74.598228" },
  },
  {
    id: "23017",
    name: "Wyoming",
    coordinates: { lat: "39.246387", long: "-84.471329" },
  },
];

export {
  API_DOMAIN,
  PROVIDER_LOGIN_URL,
  PROVIDER_LOGOUT_URL,
  SESSION_STORAGE_KEYS,
  SITE_URL,
  LOCATIONS_API_URL,
  isProductionEnv,
  AZURE_DOMAIN
};
